<template>
	<div>
		<!-- 内容 -->
		<div class="contain flex">
			<div class="page-content">
				<div class="bm-wapper">
					<div class="bm-bar">
						<step-bar :active="0"></step-bar>
					</div>
					<div class="bm-content">
						<title-bar v-if="isLoading" :titles="['招考报名',info.name]"></title-bar>
						<el-form :model="form" label-width="130px" size="small">
							<div class="form-title">报名表</div>
							<div class="form-title-bar">基本信息</div>
							<div class="form-item-wapper">
								<div class="flex">
									<div class="col2 flex1">
										<el-form-item label="姓名" :required="true">
											<el-input placeholder="请输入" v-model="form.name"></el-input>
										</el-form-item>
										<el-form-item label="性别" :required="true">
											<el-select class="labelselect" v-model="sxdata[0].data"
												@change="SelectChange($event, 0)" placeholder="请选择">
												<el-option v-for="item in sxdata[0].list" :key="item.value"
													:label="item.name" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="政治面貌" :required="true">
											<el-select class="labelselect" v-model="sxdata[1].data"
												@change="SelectChange($event, 1)" placeholder="请选择">
												<el-option v-for="item in sxdata[1].list" :key="item.value"
													:label="item.name" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="户籍地" :required="true">
											<el-cascader size="large" :options="regionData" v-model="houseHold"
												@change="handleChange">
											</el-cascader>
										</el-form-item>
										<el-form-item label="身份证号" :required="true">
											<el-input placeholder="请输入" v-model="form.idcard"></el-input>
										</el-form-item>

										<el-form-item label="婚姻状况" :required="true">
											<el-select class="labelselect" v-model="sxdata[2].data"
												@change="SelectChange($event, 2)" placeholder="请选择">
												<el-option v-for="item in sxdata[2].list" :key="item.value"
													:label="item.name" :value="item.value">
												</el-option>
											</el-select>
										</el-form-item>
										<el-form-item label="手机号码" :required="true">
											<el-input placeholder="请输入" v-model="form.phone"></el-input>
										</el-form-item>
										<el-form-item label="电子信箱">
											<el-input placeholder="请输入" v-model="form.email"></el-input>
										</el-form-item>
										<el-form-item label="邮 编">
											<el-input placeholder="请输入" v-model="form.zipCode"></el-input>
										</el-form-item>
										<el-form-item label="通信地址" style="width: 100%;" :required="true">
											<el-input placeholder="请输入" v-model="form.emailAddress"></el-input>
										</el-form-item>
										<el-form-item label="居住地" style="width: 100%;">
											<el-input placeholder="请输入" v-model="form.homeAddress"></el-input>
										</el-form-item>
									</div>
									<div class="btn-upload-pic-wapper">
										<el-upload class="avatar-uploader"
											:action="baseurl + '/Admin/Upload/uploadimage'"
											accept="image/png, image/jpeg" :show-file-list="false"
											:on-success="uploadLogoSuccess" :on-error="uploadError">
											<div class="btn-upload-pic">
												<div v-if="form.avatar">
													<img :src="defimgurl+form.avatar" fit="contain" width="100"
														height="120"></img>
												</div>
												<div v-else>
													<div class="btn-plus">
														<i class="el-icon-plus"></i>
													</div>
													<div>上传照片</div>
												</div>
											</div>
										</el-upload>
										<div class="btn-upload-pic-text">
											<i class="red">*</i>一寸照格式
										</div>
									</div>
								</div>
							</div>
							<div class="form-title-bar">教育经历</div>
							<div class="form-item-wapper">
								<div class="flex">
									<div class="col2">
										<el-form-item label="学 历" :required="true">
											<el-select style="width: 260px" v-model="form.education" placeholder="学历">
												<el-option v-for="item in educationList" :key="item.cCode" :label="item.cName"
													:value="item.cName"></el-option>
											</el-select>
											<!-- <el-input placeholder="请输入" v-model="form.education"></el-input> -->
										</el-form-item>
										<el-form-item label="毕业院校" :required="true">
											<el-input placeholder="请输入" v-model="form.school"></el-input>
										</el-form-item>
										<el-form-item label="专业" :required="true">
											<el-input placeholder="请输入" v-model="form.professional"></el-input>
										</el-form-item>
										<el-form-item label="毕业时间" :required="true">
											<el-date-picker style="width: 260px" v-model="form.educationDate" type="date" placeholder="选择日期"
												value-format="yyyy-MM-dd">
											</el-date-picker>
											<!-- <el-input placeholder="请输入" v-model="form.educationDate"></el-input> -->
										</el-form-item>
										<el-form-item label="学 位">
											<el-select style="width: 260px" v-model="form.degree" placeholder="学位">
												<el-option v-for="item in degreeList" :key="item.cCode" :label="item.cName"
													:value="item.cName"></el-option>
											</el-select>
											<!-- <el-input placeholder="请输入" v-model="form.degree"></el-input> -->
										</el-form-item>
									</div>
								</div>
							</div>
							<div class="form-title-bar">
								<i class="red">*</i>学习经历（从高中以上填起）
							</div>
							<div class="form-item-wapper">
								<el-table :data="form.learnings" border>
									<el-table-column label="起止时间">
										<template slot-scope="scope">
											<div>
												{{scope.row.startTime}}
												-
												{{scope.row.endTime}}
											</div>
										</template>
									</el-table-column>
									<el-table-column label="所在学校、专业" prop="des"></el-table-column>
									<el-table-column label="职务或表现" prop="mome">
									</el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<div>
												<el-button size="mini" @click="update(scope.$index,0)">编辑
												</el-button>
												<el-button size="mini" @click="remove(scope.$index,0)">删除
												</el-button>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<div class="btn-group-addmember">
									<el-button size="mini" icon="el-icon-plus" @click="openAdd(0)">添加学习经历
									</el-button>
								</div>
							</div>
							<div class="form-title-bar">
								<i class="red">*</i>工作经验 （正式就业）
							</div>
							<div class="form-item-wapper">
								<el-table :data="form.works" border>
									<el-table-column label="起止时间">
										<template slot-scope="scope">
											<div>
												{{scope.row.startTime}}
												-
												{{scope.row.endTime}}
											</div>
										</template>
									</el-table-column>
									<el-table-column label="工作单位" prop="des"></el-table-column>
									<el-table-column label="职务或表现" prop="mome">
									</el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<div>
												<el-button size="mini" @click="update(scope.$index,1)">编辑
												</el-button>
												<el-button size="mini" @click="remove(scope.$index,1)">删除
												</el-button>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<div class="btn-group-addmember">
									<el-button size="mini" icon="el-icon-plus" @click="openAdd(1)">添加工作经验
									</el-button>
								</div>
							</div>
							<div class="form-title-bar">
								<i class="red">*</i>工作业绩或受奖励、处分情况
							</div>
							<div class="form-item-wapper">
								<el-form-item label-width="0">
									<el-input placeholder="请输入工作业绩或受奖励、处分情况" type="textarea" :rows="5"
										v-model="form.jobs_reward">
									</el-input>
								</el-form-item>
							</div>
							<div class="form-title-bar">
								个人兴趣
							</div>
							<div class="form-item-wapper">
								<el-form-item label-width="0">
									<el-input placeholder="请输入个人兴趣" type="textarea" :rows="5" v-model="form.interest">
									</el-input>
								</el-form-item>
							</div>
							<div class="form-title-bar">特长</div>
							<div class="form-item-wapper">
								<el-form-item label-width="0">
									<el-input placeholder="请输入特长" type="textarea" :rows="5" v-model="form.specialty">
									</el-input>
								</el-form-item>
							</div>
							<div class="form-title-bar">专业职业资格证书情况</div>
							<div class="form-item-wapper">
								<el-table :data="form.professional_book" border>
									<el-table-column label="专业" prop="professional">
									</el-table-column>
									<el-table-column label="等级" prop="level"></el-table-column>
									<el-table-column label="资格证书全称" prop="name">
									</el-table-column>
									<el-table-column label="批准时间" prop="appTime"></el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<div>
												<el-button size="mini" @click="update(scope.$index,2)">编辑
												</el-button>
												<el-button size="mini" @click="remove(scope.$index,2)">删除
												</el-button>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<div class="btn-group-addmember">
									<el-button size="mini" icon="el-icon-plus" @click="openAdd(2)">添加资格证书
									</el-button>
								</div>
							</div>
							<div class="form-title-bar">
								<i class="red">*</i>家庭主要成员（父母、配偶及子女）
							</div>
							<div class="form-item-wapper">
								<el-table :data="form.home_man" border>
									<el-table-column label="关系" prop="between">
									</el-table-column>
									<el-table-column label="姓名" prop="name"></el-table-column>
									<el-table-column label="在何地何单位工作（学习）" prop="workaddress">
									</el-table-column>
									<!-- 之前字段为详细地址，数据库方面考虑，homeaddress当作手机号码字段使用 -->
									<el-table-column label="手机号码" prop="homeaddress"></el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<div>
												<el-button size="mini" @click="update(scope.$index,3)">编辑
												</el-button>
												<el-button size="mini" @click="remove(scope.$index,3)">删除
												</el-button>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<div class="btn-group-addmember">
									<el-button size="mini" icon="el-icon-plus" @click="openAdd(3)">添加成员
									</el-button>
								</div>
							</div>
							<div class="form-title-bar">
								<i class="red">*</i>佐证材料(pdf文件)
							</div>
							<div class="form-item-wapper">
								<el-table :data="form.attach" border>
									<el-table-column label="名称" prop="title">
									</el-table-column>
									<el-table-column label="操作">
										<template slot-scope="scope">
											<div>
												<el-button size="mini" @click="remove(scope.$index,4)">删除
												</el-button>
											</div>
										</template>
									</el-table-column>
								</el-table>
								<div class="btn-group-addmember">
									<el-upload class="el-button el-button--default el-button--mini"
										:action="baseurl + '/Admin/Upload/uploadfile'" :show-file-list="false"
										:headers="headers" accept=".pdf" :on-success="uploadSuccess"
										:on-error="uploadError">
										<i class="el-icon-plus"></i><span>添加佐证材料</span>
									</el-upload>
								</div>
							</div>

							<div class="form-title-bar flex">
								
								<div class="flex1"><i class="red">*</i>选择岗位</div>
								<div class="padding-right">
									<el-button type="primary" icon="el-icon-plus" size="mini"
										@click="openItem=true">选择岗位</el-button>
								</div>
							</div>
							<div>
								<el-table :data="iteminfo" border>
									<el-table-column label="序号" type="index" width="60" align="center">
									</el-table-column>
									<el-table-column label="单位" prop="examCompanyId" width="130">
									</el-table-column>
									<el-table-column label="岗位" prop="name" width="120"></el-table-column>
									<el-table-column label="要求" prop="memo"><template slot-scope="scope">
											<p v-html="return2Br(scope.row.memo)"></p>
										</template></el-table-column>
									<el-table-column label="人数" prop="num" width="60" align="center">
									</el-table-column>
								</el-table>
							</div>
						</el-form>

						<div class="foot-btn-group">
							<el-button type="primary" @click="gonext()">下一步</el-button>
							<el-button type="info" @click="gosave()">保存草稿</el-button>
							<el-button @click="goback()">取消</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<el-dialog title="添加学习经历" :visible.sync="open_learnings" :close-on-click-modal="false">
			<el-form :model="learnings_info" label-width="100px">
				<el-form-item label="起始时间" :required="true">
					<el-date-picker v-model="learnings_info.startTime" type="date" placeholder="选择日期"
						value-format="yyyy-MM-dd">
					</el-date-picker>
					<!-- <el-input v-model="learnings_info.startTime" placeholder="请输入">
					</el-input> -->
				</el-form-item>
				<el-form-item label="截止时间" :required="true">
					<el-date-picker v-model="learnings_info.endTime" type="date" placeholder="选择日期"
						value-format="yyyy-MM-dd">
					</el-date-picker>
					<!-- <el-input v-model="learnings_info.endTime" placeholder="请输入">
					</el-input> -->
				</el-form-item>
				<el-form-item label="所在学校、专业" :required="true">
					<el-input v-model="learnings_info.des" placeholder="请输入">
					</el-input>
				</el-form-item>
				<el-form-item label="职务或表现">
					<el-input v-model="learnings_info.mome" placeholder="请输入(如无请填写'无')"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="open_learnings = false">取 消</el-button>
				<el-button type="primary" @click="addform(0)">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="添加工作经历" :visible.sync="open_works" :close-on-click-modal="false">
			<el-form :model="works_info" label-width="100px">
				<el-form-item label="起始时间" :required="true">
					<el-date-picker v-model="works_info.startTime" type="date" placeholder="选择日期"
						value-format="yyyy-MM-dd">
					</el-date-picker>
					<!-- <el-input v-model="works_info.startTime" placeholder="请输入">
					</el-input> -->
				</el-form-item>
				<el-form-item label="截止时间" :required="true">
					<el-date-picker v-model="works_info.endTime" type="date" placeholder="选择日期"
						value-format="yyyy-MM-dd">
					</el-date-picker>
					<!-- <el-input v-model="works_info.endTime" placeholder="请输入">
					</el-input> -->
				</el-form-item>
				<el-form-item label="工作单位" :required="true">
					<el-input v-model="works_info.des" placeholder="请输入">
					</el-input>
				</el-form-item>
				<el-form-item label="职务或表现">
					<el-input v-model="works_info.mome" placeholder="请输入(如无请填写'无')"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="open_works = false">取 消</el-button>
				<el-button type="primary" @click="addform(1)">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="添加资格证书" :visible.sync="open_professional" :close-on-click-modal="false">
			<el-form :model="professional_book" label-width="100px">
				<el-form-item label="专业">
					<el-input v-model="professional_book.professional" placeholder="请输入">
					</el-input>
				</el-form-item>
				<el-form-item label="等级">
					<el-input v-model="professional_book.level" placeholder="请输入">
					</el-input>
				</el-form-item>
				<el-form-item label="资格证书全称">
					<el-input v-model="professional_book.name" placeholder="请输入"> </el-input>
				</el-form-item>
				<el-form-item label="批准时间">
					<el-date-picker style="width: 260px" v-model="professional_book.appTime" type="date" placeholder="选择日期"
						value-format="yyyy-MM-dd">
					</el-date-picker>
					<!-- <el-input v-model="professional_book.appTime" placeholder="请输入"> </el-input> -->
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="open_professional = false">取 消</el-button>
				<el-button type="primary" @click="addform(2)">确 定</el-button>
			</span>
		</el-dialog>


		<el-dialog title="添加成员" :visible.sync="openMember" :close-on-click-modal="false">
			<el-form :model="home_man" label-width="100px">
				<el-form-item label="关系" :required="true">
					<el-input v-model="home_man.between" placeholder="请输入">
					</el-input>
				</el-form-item>
				<el-form-item label="姓名" :required="true">
					<el-input v-model="home_man.name" placeholder="请输入">
					</el-input>
				</el-form-item>
				<el-form-item label="在何地何单位工作（学习）">
					<el-input v-model="home_man.workaddress" placeholder="请输入"> </el-input>
				</el-form-item>
				<el-form-item label="手机号码" :required="true">
					<el-input v-model="home_man.homeaddress" placeholder="请输入"> </el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="openMember = false">取 消</el-button>
				<el-button type="primary" @click="addform(3)">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="选择岗位" :visible.sync="openItem" :close-on-click-modal="false">
			<div v-if="itemlist.length > 0">
				<template>
					<el-table :data="itemlist" stripe style="width: 100%" border header-row-class-name="headerrowclass"
						row-class-name="rowclass">
						<!-- <el-table-column label="序号" type="index" width="60" align="center">
						</el-table-column> -->
						<el-table-column prop="examNumber" label="序号" width="60">
						</el-table-column>
						<el-table-column prop="examAdministration" label="主管部门" width="100">
						</el-table-column>
						<el-table-column prop="examCompanyId" label="招聘单位" width="100">
						</el-table-column>
						<el-table-column prop="name" label="岗位" width="100">
						</el-table-column>
						<el-table-column prop="memo" label="要求">
							<template slot-scope="scope">
								<p v-html="return2Br(scope.row.memo)"></p>
							</template>
						</el-table-column>
						<el-table-column prop="num" label="人数" width="50" align="center">
						</el-table-column>
						<el-table-column label="操作" width="60" align="center">
							<template slot-scope="scope">
								<a @click="examitem(scope.row)" style="color: #276bf2;margin-right: 10px;">选择</a>
							</template>
						</el-table-column>
					</el-table>
				</template>
			</div>
			<el-empty v-if="itemlist.length < 1" :image-size="200"></el-empty>
			<div style="text-align: center; margin-top: 20px">
				<el-pagination hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="selfrom.pageNumber" :page-sizes="[20, 50, 100, 150, 200]"
					:page-size="selfrom.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import {
		GetExamModel,
		AddExamSignUp,
		ExamSignUpInfo,
		ExamCompanySelectList,
		ExamCompanyItemSelectList
	} from "@/api/user";
	import {
		Getposilist,
		GetEnum
	} from "@/api/home";
	import StepBar from './stepbar.vue';
	import TitleBar from './titlebar.vue';
	import global from "@/assets/js/globalconfig";
	import {
		regionData
	} from "element-china-area-data";
	export default {
		components: {
			StepBar,
			TitleBar
		},
		data() {
			return {
				isLoading: true,
				headers: {
					token: localStorage.getItem("token"),
				},
				id: 0,
				info: {},
				userinfo: {},
				form: {
					id: 0,
					personId: 0,
					examId: 0,
					examCompanyId: "",
					examCompanyItemId: 0,
					name: "",
					sex: 0,
					political: 0,
					birthday: '',
					idcard: '',
					marry: 2,
					houseHold: "",
					school: '',
					professional: '',
					educationDate: '',
					education: '',
					degree: '',
					learnings: [],
					works: [],
					jobs_reward: '',
					interest: '',
					specialty: '',
					professional_book: [],
					home_man: [],
					homeAddress: '',
					emailAddress: '',
					zipCode: '',
					phone: '',
					email: '',
					avatar: '',
					attach: [],
					state: 0,
					examCompanyId: "",
					examCompanyItemId: 0,
				},
				houseHold: [],
				activeName: 'tab1',

				learnings_info: {
					startTime: '',
					endTime: '',
					des: '',
					mome: ''
				},
				open_learnings: false,

				works_info: {
					startTime: '',
					endTime: '',
					des: '',
					mome: ''
				},
				open_works: false,

				professional_book: {
					professional: '',
					level: '',
					name: '',
					appTime: ''
				},
				open_professional: false,

				home_man: {
					between: '',
					name: '',
					workaddress: '',
					homeaddress: ''
				},
				openMember: false,

				openItem: false,
				operType: 'add',
				educationList: [],
				degreeList: [],
				regionData,
				optionmz: [],
				selfrom: {
					searchExamId: 0,
					pageNumber: 1,
					pageSize: 5,
					searchKey: "",
				},
				total: 0,
				itemlist: [],
				iteminfo: [],
				baseurl: global.baseurl,
				defimgurl: global.baseimgurl,

				sxdata: [{
						name: "性别",
						index: 0,
						list: [{
							name: "男",
							value: 0
						}, {
							name: "女",
							value: 1
						}],
						data: '男'
					}, {
						name: "政治面貌",
						index: 0,
						list: [],
						data: ''
					},
					{
						name: "婚姻",
						index: 0,
						list: [],
						data: ''
					},
					{
						name: "招聘企业",
						index: 0,
						list: [],
						data: ''
					},
					{
						name: "招聘岗位",
						index: 0,
						list: [],
						data: ''
					}
				],
			};
		},
		created() {
			this.GetEnum();
			this.id = this.$route.query.id;
			if (!isnull(this.id)) {
				this.Getinfo(this.id);
			} else {
				this.$router.push("/zhaokaobm")
			}
			if (!isnull(localStorage.getItem("userinfo"))) {
				this.userInfo = JSON.parse(localStorage.getItem("userinfo"));
				this.form.personId = parseInt(this.userInfo.peR_ID)
			}
			
			if (!isnull(localStorage.getItem("examCompanyInfo_" + this.userInfo.peR_ID))) {
				var examCompanyInfo = JSON.parse(localStorage.getItem("examCompanyInfo_" + this.userInfo.peR_ID));
				this.form.examCompanyId = examCompanyInfo.examCompanyId;
				this.form.examCompanyItemId = examCompanyInfo.examCompanyItemId;
			}
			if (!isnull(localStorage.getItem("zhaokaobm_" + this.userInfo.peR_ID))) {
				// this.form = JSON.parse(localStorage.getItem("zhaokaobm"));
				this.form = Object.assign(this.form, JSON.parse(localStorage.getItem("zhaokaobm_" + this.userInfo.peR_ID)));
				//this.GetInfo()
			} else {
				this.GetExamByPid()
			}
			this.GetInfo();
			this.Getposilists(1, "学历");
			this.Getposilists(2, "学位");
		},
		methods: {
			Getposilists(type, datas) {
				var _this = this;
				Getposilist({
					types: datas
				}).then((res) => {
					if (res.success) {
						switch (type) {
							case 1: //学历
								_this.educationList = res.data.rows;
								_this.educationList.sort(function(a, b) {
									return a.cCode - b.cCode;
								})
								break;
							case 2: //学位
								_this.degreeList = res.data.rows;
								_this.degreeList.sort(function(a, b) {
									return a.cCode - b.cCode;
								})
								break;
							default:
								break;
						}
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//获取学历
			getEducationList(data) {
				if (isnull(data)) {
					return "";
				}
				for (let i = 0; i < this.educationList.length; i++) {
					if (this.educationList[i].cCode == data)
						return this.educationList[i].cName
				}
			},
			return2Br(str) {
				return str.replace(/\r?\n/g, "<br />");
			},
			Getinfo(id) {
				this.isLoading = false;
				GetExamModel({
					id: id
				}).then((res) => {
					if (res.success) {
						this.info = res.data[0];
						this.isLoading = true;
					}
				});
			},
			handleChange(value) {
				this.form.houseHold = value[0] + "," + value[1] + "," + value[2];
			},
			GetInfo() {
				var _this = this;
				if (!isnull(_this.form.memberOfFamily))
					_this.memberOfFamily = JSON.parse(_this.form.memberOfFamily)

				var hh = _this.form.houseHold.split(',');
				if (hh.length > 2)
					_this.houseHold = [hh[0], hh[1], hh[2]];

				_this.sxdata[0].list.forEach((element) => {
					if (element.value == _this.form.sex) {
						_this.sxdata[0].data = element.name;
					}
				});
				_this.form.learnings = _this.isArray(_this.form.learnings);
				_this.form.works = _this.isArray(_this.form.works);
				_this.form.professional_book = _this.isArray(_this.form.professional_book);
				_this.form.home_man = _this.isArray(_this.form.home_man);
				if (_this.form.attach != '')
					_this.form.attach = _this.isArray(_this.form.attach);
				else
					_this.form.attach = []
				this.GetExamItemList();
			},
			GetExamByPid() {
				var _this = this;
				var par = {
					KW: this.form.personId
				};
				if (this.form.id > 0) {
					par = {
						id: this.form.id
					};
				}
				ExamSignUpInfo(par).then((res) => {
					if (res.success) {
						// 默认数据 by-zzc
						_this.form.name = _this.userInfo.peR_NAME;
						_this.form.phone = _this.userInfo.peR_CELLPHONE;
						_this.form.homeAddress = _this.userInfo.peR_ADDRESS;

						if (res.data.length > 0) {
							_this.form = res.data[0];
							//_this.form.id = 0;
							//_this.form.examId = 0;
							//_this.form.examCompanyId = 0;
							//_this.form.examCompanyItemId = 0;
							_this.GetInfo()
						} else {
							_this.form.name = _this.userInfo.peR_NAME;
							//_this.form.sex = _this.userInfo.peR_GENDER;
							_this.form.phone = _this.userInfo.peR_PHONE;
							//_this.form.birthday = _this.userInfo.peR_BIRTHDAY;
							_this.form.homeAddress = _this.userInfo.peR_ADDRESS;
							//_this.form.avatar = _this.userInfo.peR_IMG_PATH;
							//_this.form.education = _this.userInfo.peR_HEDU_NAME;
							//if (_this.form.sex == null) _this.form.sex = 0;
							this.GetExamItemList();
						}
					}
				})

			},
			isArray(v) {
				if (!Array.isArray(v))
					v = JSON.parse(v);
				if (!Array.isArray(v))
					v = JSON.parse(v);
				if (!Array.isArray(v))
					v = JSON.parse(v);
				if (!Array.isArray(v))
					v = JSON.parse(v);
				return v
			},
			examitem(item) {
				this.iteminfo = []
				this.iteminfo.push(item);
				this.form.examId = item.examId;
				this.form.examCompanyId = item.examCompanyId;
				this.form.examCompanyItemId = item.id;
				this.openItem = false;
			},
			handleSizeChange(val) {
				this.selfrom.pageNumber = 1;
				this.selfrom.pageSize = val;
				this.GetExamItemList();
			},
			handleCurrentChange(val) {
				this.selfrom.pageNumber = val;
				this.GetExamItemList();
			},
			GetExamItemList() {
				this.selfrom.KW = this.id;
				ExamCompanyItemSelectList(this.selfrom).then((res) => {
					if (res.success) {
						this.itemlist = res.data;
						if (this.form.examCompanyItemId != 0) {
							this.itemlist.forEach((element) => {
								if (element.id == this.form.examCompanyItemId) {
									this.iteminfo = []
									this.iteminfo.push(element);
								}
							})
						}
						if (this.iteminfo == []) {
							this.form.examId = 0;
							this.form.examCompanyId = 0;
							this.form.examCompanyItemId = 0;
						}
					}
				});
			},
			//上传logo成功
			uploadLogoSuccess(res, file) {
				this.$message.success("上传成功!");
				this.form.avatar = res.data.obj.files;
			},
			uploadSuccess(res, file) {
				this.$message.success("上传成功!");
				if (res.success) {
					if (res.data.code > 0) {
						this.form.attach.push({
							...res.data.obj
						});
					} else {
						this.$message.error(res.data.msg);
					}
				}
				//this.form.avatar = res.data.obj.files;
			},
			uploadError(res, file) {
				this.$message.error("上传失败!");
			},
			SelectChange(e, i) {
				var _this = this;
				_this.sxdata[i].list.forEach((element) => {
					if (element.value == e) {
						_this.sxdata[i].data = element.name;
					}
				});
				switch (i) {
					case 0:
						_this.form.sex = parseInt(e);
						break;
					case 1:
						_this.form.political = parseInt(e);
						break;
					case 2:
						_this.form.marry = parseInt(e);
						break;
					default:
						break;
				}
			},
			GetEnum() {
				this.GetPoliticalType();
				this.GetMerryStatus();
			},
			GetPoliticalType() {
				GetEnum({
					KW: "PoliticalType"
				}).then((res) => {
					if (res.success) {
						this.sxdata[1].list = res.data
						if (this.form.political != '') {
							this.sxdata[1].list.forEach((element) => {
								if (element.value == this.form.political) {
									this.sxdata[1].data = element.name;
								}
							});
						} else {
							this.form.political = res.data[0].value
							this.sxdata[1].data = res.data[0].name
						}
					}
				});
			},
			GetMerryStatus() {
				GetEnum({
					KW: "MerryStatus"
				}).then((res) => {
					if (res.success) {
						this.sxdata[2].list = res.data
						if (this.form.marry != '') {
							this.sxdata[2].list.forEach((element) => {
								if (element.value == this.form.marry) {
									this.sxdata[2].data = element.name;
								}
							});
						} else {
							this.form.marry = res.data[0].value
							this.sxdata[2].data = res.data[0].name
						}
					}
				});
			},

			update(index, i) {
				if (i == 1) {
					this.operworksIndex = index;
					this.works_info = {
						...this.form.works[index]
					};
					this.open_works = true;
				} else if (i == 2) {
					this.operprofessionalIndex = index;
					this.professional_book = {
						...this.form.professional_book[index]
					};
					this.open_professional = true;
				} else if (i == 3) {
					this.operMemberIndex = index;
					this.home_man = {
						...this.form.home_man[index]
					};
					this.openMember = true;
				} else {
					this.operlearningsIndex = index;
					this.learnings_info = {
						...this.form.learnings[index]
					};
					this.open_learnings = true;
				}
			},
			remove(index, i) {
				if (i == 1) {
					this.form.works.splice(index, 1);
				} else if (i == 2) {
					this.form.professional_book.splice(index, 1);
				} else if (i == 3) {
					this.form.home_man.splice(index, 1);
				} else if (i == 4) {
					this.form.attach.splice(index, 1);
				} else {
					this.form.learnings.splice(index, 1);
				}
			},
			addform(i) {
				//工作经验
				if (i == 1) {
					if (this.operworksIndex == null) {
						if (this.form.works.length >= 5) {
							this.$message.error("工作简历信息不能多余5条");
							return false;
						}
						if (isnull(this.works_info.startTime)) {
							this.$message.error("请填写起始时间");
							return false;
						}
						if (isnull(this.works_info.endTime)) {
							this.$message.error("请填写截止时间");
							return false;
						}
						if(!utils.compareTime(this.works_info.startTime, this.works_info.endTime)) {
							this.$message.error("请填写正确的起始和截止时间");
							return false;
						}
						if (isnull(this.works_info.des)) {
							this.$message.error("请填写工作单位");
							return false;
						}
						if (this.works_info.mome == "") this.works_info.mome = "无"
						this.form.works.push({
							...this.works_info
						});
					} else {
						let memberOfFamily = JSON.parse(JSON.stringify(this.form.works))
						memberOfFamily[this.operworksIndex] = {
							...this.works_info
						};
						this.form.works = memberOfFamily;
					}
					this.open_works = false;
				//专业证书
				} else if (i == 2) {
					if (this.operprofessionalIndex == null) {
						this.form.professional_book.push({
							...this.professional_book
						});
					} else {
						let memberOfFamily = JSON.parse(JSON.stringify(this.form.professional_book))
						memberOfFamily[this.operprofessionalIndex] = {
							...this.professional_book
						};
						this.form.professional_book = memberOfFamily;
					}
					this.open_professional = false;
				//家庭成员
				} else if (i == 3) {
					if (this.operMemberIndex == null) {
						if (isnull(this.home_man.between)) {
							this.$message.error("请填写关系");
							return false;
						}
						if (isnull(this.home_man.name)) {
							this.$message.error("请填写姓名");
							return false;
						}
						if (isnull(this.home_man.homeaddress)) {
							this.$message.error("请填写手机号码");
							return false;
						}
						// 校验手机号码
						var verify_res = utils.telVerify(this.home_man.homeaddress);
						if (!verify_res.state) {
							this.$message.error(verify_res.message);
							return false;
						}
						this.form.home_man.push({
							...this.home_man
						});
					} else {
						let memberOfFamily = JSON.parse(JSON.stringify(this.form.home_man))
						memberOfFamily[this.operMemberIndex] = {
							...this.home_man
						};
						this.form.home_man = memberOfFamily;
					}
					this.openMember = false;
				//学习经历
				} else {
					if (this.operlearningsIndex == null) {
						if (isnull(this.learnings_info.startTime)) {
							this.$message.error("请填写起始时间");
							return false;
						}
						if (isnull(this.learnings_info.endTime)) {
							this.$message.error("请填写截止时间");
							return false;
						}
						if(!utils.compareTime(this.learnings_info.startTime, this.learnings_info.endTime)) {
							this.$message.error("请填写正确的起始和截止时间");
							return false;
						}
						if (isnull(this.learnings_info.des)) {
							this.$message.error("请填写所在学校、专业");
							return false;
						}
						if (this.learnings_info.mome == "") this.learnings_info.mome = "无"
						this.form.learnings.push({
							...this.learnings_info
						});
					} else {
						let memberOfFamily = JSON.parse(JSON.stringify(this.form.learnings))
						memberOfFamily[this.operprofessionalIndex] = {
							...this.learnings_info
						};
						this.form.learnings = memberOfFamily;
					}
					this.open_learnings = false;
				}
			},
			openAdd(i) {
				if (i == 1) {
					if (this.form.works.length >= 5) {
						this.$message.error("工作简历信息不能多余5条");
						return false;
					}
					this.operworksIndex = null;
					this.works_info = {
						startTime: '',
						endTime: '',
						des: '',
						mome: ''
					};
					this.open_works = true;
				} else if (i == 2) {
					if (this.form.professional_book.length >= 3) {
						this.$message.error("职业证书信息不能多余3条");
						return false;
					}
					this.operprofessionalIndex = null;
					this.professional_book = {
						professional: '',
						level: '',
						name: '',
						appTime: ''
					};
					this.open_professional = true;
				} else if (i == 3) {
					if (this.form.home_man.length >= 4) {
						this.$message.error("家庭主要成员信息不能多余4条");
						return false;
					}
					this.operMemberIndex = null;
					this.home_man = {
						between: '',
						name: '',
						workaddress: '',
						homeaddress: ''
					};
					this.openMember = true;
				} else {
					if (this.form.learnings.length >= 3) {
						this.$message.error("学习经历信息不能多余3条");
						return false;
					}
					this.operlearningsIndex = null;
					this.learnings_info = {
						startTime: '',
						endTime: '',
						des: '',
						mome: ''
					};
					this.open_learnings = true;
				}
			},
			gosave() {
				localStorage.setItem("zhaokaobm_" + this.userInfo.peR_ID, JSON.stringify(this.form));
				this.$message({
					message: "保存成功",
					type: "success",
				});
			},
			gonext(e) {
				var _this = this;
				if (isnull(_this.form.name)) {
					this.$message.error("请填写姓名");
					return false;
				}
				if (isnull(_this.form.sex)) {
					this.$message.error("请选择性别");
					return false;
				}
				if (isnull(_this.form.political)) {
					this.$message.error("请选择政治面貌");
					return false;
				}
				if (isnull(_this.form.houseHold)) {
					this.$message.error("请填写户籍地");
					return false;
				}
				if (isnull(_this.form.idcard)) {
					this.$message.error("请填写身份证号");
					return false;
				}
				if (isnull(_this.form.marry)) {
					this.$message.error("请选择婚姻状况");
					return false;
				}
				if (isnull(_this.form.phone)) {
					this.$message.error("请填写手机号码");
					return false;
				}
				if (isnull(_this.form.emailAddress)) {
					this.$message.error("请填写通信地址");
					return false;
				}
				if (isnull(_this.form.education)) {
					this.$message.error("请填写学历");
					return false;
				}
				if (isnull(_this.form.educationDate)) {
					this.$message.error("请填写毕业时间");
					return false;
				}
				if (isnull(_this.form.school)) {
					this.$message.error("请填写毕业院校");
					return false;
				}
				if (isnull(_this.form.professional)) {
					this.$message.error("请填写专业");
					return false;
				}
				if (isnull(_this.form.avatar)) {
					this.$message.error("请上传一寸照");
					return false;
				}
				if (_this.form.examCompanyItemId == 0) {
					this.$message.error("请选择一个岗位");
					return false;
				}

				if (this.form.learnings.length == 0) {
					this.$message.error("学习经历信息至少有一条");
					return false;
				}
				if (this.form.works.length == 0) {
					this.$message.error("工作简历信息至少有一条");
					return false;
				}
				if (this.form.home_man.length == 0) {
					this.$message.error("家庭主要成员信息至少有一条");
					return false;
				}
				if (this.form.attach.length == 0) {
					this.$message.error("佐证材料信息至少有一条");
					return false;
				}
				// 校验手机号码
				var verify_phone_res = utils.telVerify(_this.form.phone);
				if (!verify_phone_res.state) {
					this.$message.error(verify_phone_res.message);
					return false;
				}
				// 校验身份证
				var verify_card_res = utils.idCardVerify(_this.form.idcard);
				if (!verify_card_res.state) {
					this.$message.error(verify_card_res.message);
					return false;
				}

				// 非法字符校验
				console.log(JSON.stringify(_this.form));
				var formJsonStr = JSON.stringify(_this.form);
				var sqlVerifyList = ["'", "*", "--"];
				for (var index in sqlVerifyList) {
					if (formJsonStr.includes(sqlVerifyList[index])){
						this.$message.error("存在非法字符: " + sqlVerifyList[index]);
						return false;
					}
				}


				this.$confirm("请认真核对填报信息是否正确，保存后不可更改", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					// 通过校验
					_this.form.birthday = _this.form.idcard.substring(6, 10) + "-" + _this.form.idcard.substring(10, 12) +
					"-" + _this.form.idcard.substring(12, 14);
					_this.form.learnings = JSON.stringify(_this.isArray(_this.form.learnings));
					_this.form.works = JSON.stringify(_this.isArray(_this.form.works));
					_this.form.professional_book = JSON.stringify(_this.isArray(_this.form.professional_book));
					_this.form.home_man = JSON.stringify(_this.isArray(_this.form.home_man));
					_this.form.attach = JSON.stringify(_this.isArray(_this.form.attach));
					AddExamSignUp(_this.form).then((res) => {
						if (res.success) {
							localStorage.removeItem("zhaokaobm_" + this.userInfo.peR_ID);
							// 将本地存储中报名岗位信息也一并删除
							localStorage.removeItem("examCompanyInfo_" + this.userInfo.peR_ID);
							this.$router.push("bmComplate?id=" + res.data)
						}
						else {
							this.$message.error(res.msg);
						}
					})
				})
			},
			goreset(e) {
				this.form = {};
				this.memberOfFamily = [];
				this.houseHold = [];
			},
			goback() {
				this.$router.push("zhaokaoInfo?id=" + this.id)
			},
		},
	};
</script>
<style lang="scss" scoped>
	.contain {
		background-color: #fff;
		min-height: calc(100vh - 125px);
	}

	.red {
		color: #F56C6C;
		margin-right: 4px;
	}

	.bm-wapper {
		padding: 20px 0;
		display: flex;
	}

	.page-content {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;

		::v-deep .el-tabs__header {
			margin: 0;
		}

		::v-deep .el-tabs__item {
			padding: 0 80px;
		}
	}

	.bm-bar {
		width: 220px;
	}

	.bm-content {
		flex: 1;
		padding: 0 48px;
	}

	.form-title {
		font-size: 28px;
		text-align: center;
		padding: 10px 0;
	}

	.form-title-bar {
		height: 40px;
		line-height: 40px;
		background: #f8f8f8;
		border-left: 4px solid #5bb0ff;
		padding-left: 25px;
		color: #101010;
		font-size: 15px;
	}

	.flex {
		display: flex;
	}

	.flex1 {
		flex: 1;
	}

	.padding-right {
		padding-right: 10px;
	}

	.form-item-wapper {
		padding-top: 10px;
	}

	.col2 {
		display: flex;
		flex-wrap: wrap;

		::v-deep .el-form-item {
			width: 50%;
		}

		::v-deep .el-form-item__label {
			font-size: 12px;
			color: #101010;
		}

		::v-deep .el-form-item__content {
			position: relative;

			i.xz {
				position: absolute;
			}
		}
	}

	.btn-upload-pic-wapper {
		padding-top: 20px;
		width: 184px;
	}

	.avatar-uploader {
		margin-left: auto;
		width: 104px;
	}

	.btn-upload-pic {
		width: 104px;
		height: 125px;
		background: #f8f8f8;
		color: rgba(0, 0, 0, 0.65);
		border: 1px solid dashed;
		text-align: center;
		font-size: 14px;
		cursor: pointer;
	}

	.btn-plus {
		padding: 25px 0;
		font-size: 24px;
	}

	.btn-upload-pic-text {
		color: rgba(0, 0, 0, 0.45);
		text-align: center;
		margin-left: auto;
		width: 104px;
		font-size: 14px;
		padding-top: 12px;
	}

	::v-deep .el-select {
		display: unset;
	}

	::v-deep .el-date-editor {
		width: 100%;
	}

	::v-deep .el-cascader {
		display: unset;
	}

	::v-deep .el-table th {
		background-color: #efefef;
	}


	.foot-btn-group {
		padding-top: 80px;
		padding-bottom: 20px;
	}

	.remark {
		font-size: 14px;
	}

	.remark-title {
		color: rgba(253, 54, 54, 1);
	}

	.btn-group-addmember {
		padding: 10px 0;
		text-align: center;
	}
</style>